<template>
  <v-dialog
    max-width="500px"
    v-model="dialog"
    persistent
    >
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text class="text-center">
        <v-icon x-large class="icon mb-3" color="hemp">mdi-alert-circle</v-icon>
        <h4 class="text-subtitle-1">Статистика не готова! Пожалуйста ожидайте получения полной статистики.</h4>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          elevation="4"
          color="hemp"
          dark
          @click="$emit('close')"
        >Ок</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'DialogStatisticsNotReady',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
