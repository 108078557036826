<template>
  <v-row>
    <v-col>
      <v-row class="align-center mb-5 mt-5">
        <vuetify-audio
        ref="audio"
        color="primary"
        :file="audioFile"
        downloadable
        autoPlay
        class="ml-3 mr-4"
        ></vuetify-audio>
        <v-col cols="12" md="auto">
          <h4 class="text-h6">Детальная статистика по номерам</h4>
        </v-col>

        <v-col class="flex-grow-1 pl-4">
          <v-text-field
            placeholder="Поиск по номеру"
            solo
            append-icon="mdi-magnify"
            hide-details="auto"
            v-model="search"
          />
        </v-col>

        <v-col cols="auto">
          <v-btn fab color="light-green darken-4" tile small elevation="0" class="rounded" dark @click="downloadExcel">
            <v-icon>mdi-file-excel</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            class="elevation-1 table"
            item-key="id"
            multi-sort
            loading-text="Загрузка... Пожалуйста, подождите"
            :sort-by="['id']"
            :sort-desc="[true]"
            :headers="headers"
            :items="filteredStatistic"
            :single-select="true"
            :loading="isLoading"
            :footer-props="{
              itemsPerPageOptions: [50,100,-1],
              showFirstLastPage: true,
              firstIcon: 'mdi-chevron-double-left',
              lastIcon: 'mdi-chevron-double-right',
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right'
            }"
          >
             <template v-slot:item.dateOfCall="{ item }">
              {{ item.dateOfCall | datetime }}
            </template>

            <template v-slot:item.number="{ item }">
              {{ item.number | phoneFilter }}
            </template>

            <template v-slot:item.result="{ item }">
              {{ item.isSuccess | resultFilter }}
            </template>

            <template v-slot:item.duration="{ item }">
              {{ item.duration | durationFilter }}
            </template>

            <template v-slot:item.digitsDialed="{ item }">
              {{ item.digitsDialed ? item.digitsDialed : item.deliveredSpeech | digitsDialedFilter }}
            </template>
            <!-- <template v-slot:item.ivrMenuStatus="{ item }">
              {{ item.ivrMenuStatus | ivrMenuStatusFilter }}
            </template> -->
            <template v-slot:footer.page-text="options">
              <span>{{ options.pageStart }}-{{ options.pageStop }} из {{ statistic.quantityNumbers }}</span>
            </template>

            <template v-slot:item.hot="{ item }">
              {{ item.hot | hotFilter }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn v-if="item.duration > 0" icon small class="ml-1" @click="playAudio(item)"><v-icon v-if="item.recordUrl!==audioFile">mdi-play-outline</v-icon><v-icon color="hemp" v-else>mdi-pause</v-icon></v-btn>
              <!-- <v-btn v-if="item.duration > 0" icon small class="ml-1" @click="playAudio(item)"><div v-if="item.recordUrl!==audioFile">1</div><div v-else>2</div></v-btn> -->
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import { VueMaskFilter } from 'v-mask';
// import { typesCriteria, criteriaDigits, ivrStatuses } from '../../manager-autocall/criteria';
import { ivrStatuses } from '../../manager-autocall/criteria';
// import { StatisticsAction } from './StatisticsAction.vue';

export default {
  name: 'DetailStatistic',
  props: {
    statistic: Object,
  },
  components: {
    VuetifyAudio: () => import('vuetify-audio'),
    // StatisticsAction,
  },
  data() {
    return {
      search: '',
      isLoading: false,
      audioFile: null,
      headers: [
        { text: 'Дата', value: 'dateOfCall', align: 'center' },
        { text: 'Номер', value: 'number', align: 'center', sortable: false },
        { text: 'Длительность звонка', value: 'duration', align: 'center', sortable: false },
        { text: 'Нажатие', value: 'digitsDialed', align: 'center' },
        { text: 'Голос', value: 'deliveredSpeech', align: 'center' },
        { text: 'Статус', value: 'result', align: 'center' },
        { text: 'Hot', value: 'hot', align: 'center' },
        { text: 'Действия', value: 'actions', align: 'center', sortable: false },
      ],
    };
  },
  computed: {
    filteredStatistic() {
      return this.search
        ? this.statistic.numbers.filter(stat => ~stat.number.indexOf(this.search.replace(/\D/g, '')))
        : this.statistic.numbers;
    },
  },
  methods: {
    ...mapActions(['DOWNLOAD_STATISTIC_AS_EXCEL', 'GET_NUMBERS_STATISTIC']),
    downloadExcel() {
      const { group, autocall } = this.$route.query;

      this.DOWNLOAD_STATISTIC_AS_EXCEL({ groupId: group, autocallId: autocall });
    },
    // handlePagination(opts) {
    //   const { pageStop, itemsLength } = opts;

    //   if (pageStop === itemsLength && this.statistic.quantityNumbers > itemsLength) {
    //     this.isLoading = true;

    //     this.GET_NUMBERS_STATISTIC({
    //       groupId: this.$route.query.group,
    //       autocallId: this.$route.query.autocall,
    //       page: this.statistic.loadedPages + 1,
    //     }).finally(() => {
    //       this.isLoading = false;
    //     });
    //   }
    // },
    handlePagination(opts) {
      const { pageStop, itemsLength } = opts;

      if (pageStop === itemsLength && this.statistic.quantity > itemsLength) {
        this.isLoading = true;

        this.GET_NUMBERS_STATISTIC({
          groupId: this.$route.query.group,
          autocallId: this.$route.query.autocall,
          page: this.statistic.loadedPages + 1,
        }).finally(() => {
          this.isLoading = false;
        });
      }
    },
    playAudio(item) {
      if (this.audioFile === item.recordUrl) {
        this.$refs.audio.stop();
        this.audioFile = null;
      } else {
        this.audioFile = item.recordUrl;
        // this.$refs.audio.play();
      }
    },
  },
  filters: {
    datetime(date) {
      if (!date) return 'Нет даты';

      return new Date(date).toLocaleString('ru-RU', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
    },
    phoneFilter(val) {
      if (val.length === 10) {
        return VueMaskFilter(val, '8 (###) ###-####');
      }
      return VueMaskFilter(val, '# (###) ###-########');
    },
    resultFilter(result) {
      return result ? 'Успешный' : 'Не успешный';
    },
    hotFilter(hot) {
      return hot ? 'Горячий' : 'Не горячий';
    },
    durationFilter(sec) {
      const minutes = Math.floor(sec / 60);
      const seconds = sec - (minutes * 60);

      return minutes ? `${minutes} мин. ${seconds} сек.` : `${seconds} сек.`;
    },
    digitsDialedFilter(digit) {
      // return (digit && digit !== 'none') ? criteriaDigits?.find(c => c.id === digit)?.label ?? `Нажатие на "${digit}` : 'Нет';
      return (digit && digit !== 'none') ? `"${digit}"` : '-';
    },
    ivrMenuStatusFilter(status) {
      return status ? ivrStatuses?.find(c => c.id === status)?.label : 'Нет';
    },
  },
};
</script>
