<script>
import merge from 'deepmerge';

export default {
  name: 'mixin',
  props: {
    series: {
      type: Array,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  computed: {
    chartOptions() {
      return merge(this.defaultOptions, this.options);
    },
  },
};
</script>

<style scoped>

</style>
