<template>
  <div>
    <TreemapChart
      :series="series"
      :options="options"
    />
  </div>
</template>

<script>
import TreemapChart from '../../../../components/charts/TreemapChart.vue';

export default {
  name: 'GeneralCampaignStatistics',
  components: { TreemapChart },
  props: {
    autocall: {
      type: Object,
      required: true,
    },
    general: {
      type: Object && undefined,
      required: true,
    },
  },
  data() {
    return {
      options: {
        height: 250,
        title: {
          text: `Звонки: ${this.autocall.name}`,
          align: 'center',
        },
      },
    };
  },
  computed: {
    series() {
      return [
        {
          data: [
            {
              x: 'Успешные',
              y: this.general.isGood,
            },
            {
              x: 'Не успешные',
              y: this.general.isBad,
            },
          ],
        },
      ];
    },
  },
};
</script>
