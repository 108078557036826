var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',[_c('v-row',{staticClass:"align-center mb-5 mt-5"},[_c('vuetify-audio',{ref:"audio",staticClass:"ml-3 mr-4",attrs:{"color":"primary","file":_vm.audioFile,"downloadable":"","autoPlay":""}}),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('h4',{staticClass:"text-h6"},[_vm._v("Детальная статистика по номерам")])]),_c('v-col',{staticClass:"flex-grow-1 pl-4"},[_c('v-text-field',{attrs:{"placeholder":"Поиск по номеру","solo":"","append-icon":"mdi-magnify","hide-details":"auto"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"rounded",attrs:{"fab":"","color":"light-green darken-4","tile":"","small":"","elevation":"0","dark":""},on:{"click":_vm.downloadExcel}},[_c('v-icon',[_vm._v("mdi-file-excel")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1 table",attrs:{"item-key":"id","multi-sort":"","loading-text":"Загрузка... Пожалуйста, подождите","sort-by":['id'],"sort-desc":[true],"headers":_vm.headers,"items":_vm.filteredStatistic,"single-select":true,"loading":_vm.isLoading,"footer-props":{
            itemsPerPageOptions: [50,100,-1],
            showFirstLastPage: true,
            firstIcon: 'mdi-chevron-double-left',
            lastIcon: 'mdi-chevron-double-right',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right'
          }},scopedSlots:_vm._u([{key:"item.dateOfCall",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("datetime")(item.dateOfCall))+" ")]}},{key:"item.number",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("phoneFilter")(item.number))+" ")]}},{key:"item.result",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("resultFilter")(item.isSuccess))+" ")]}},{key:"item.duration",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("durationFilter")(item.duration))+" ")]}},{key:"item.digitsDialed",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("digitsDialedFilter")(item.digitsDialed ? item.digitsDialed : item.deliveredSpeech))+" ")]}},{key:"footer.page-text",fn:function(options){return [_c('span',[_vm._v(_vm._s(options.pageStart)+"-"+_vm._s(options.pageStop)+" из "+_vm._s(_vm.statistic.quantityNumbers))])]}},{key:"item.hot",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("hotFilter")(item.hot))+" ")]}},{key:"item.actions",fn:function({ item }){return [(item.duration > 0)?_c('v-btn',{staticClass:"ml-1",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.playAudio(item)}}},[(item.recordUrl!==_vm.audioFile)?_c('v-icon',[_vm._v("mdi-play-outline")]):_c('v-icon',{attrs:{"color":"hemp"}},[_vm._v("mdi-pause")])],1):_vm._e()]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }