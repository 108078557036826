<template>
  <header class="elevation-3 mb-5 mt-n5">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <span v-if="autocall.name">
            Кампания: <strong>{{ autocall.name }}</strong>
          </span>
          <v-skeleton-loader v-else type="text" width="150"/>
          <br />
          <span v-if="autocall.dateCreated">
            Дата создания: <strong>{{ dateCreated }}</strong>
          </span>
          <v-skeleton-loader v-else type="text" width="150"/>
          <br />
          <span v-if="autocall.dateStop">
            Дата планового завершения: <strong>{{ dateStop }}, {{ timezone.label }}</strong>
          </span>
          <v-skeleton-loader v-else type="text" width="150"/>
          <v-divider class="my-1" />
          <span v-if="autocall.dateStart">
            Дата запуска: <strong>{{ dateStart }}, {{ timezone.label }}</strong>
          </span>
          <v-skeleton-loader v-else type="text" width="150"/>
          <br />
          <span v-if="autocall.dateEnd">
            Дата фактического завершения: <strong>{{ dateEnd }}, {{ timezone.label }}</strong>
          </span>
          <span v-else>
            Дата фактического завершения: <strong>Нет данных</strong>
          </span>
          <br />
          <span v-if="bases">
            Используемые списки:
                <span v-for="({ name }, key) in bases" :key="key"><strong>{{ name }} </strong></span>
          </span>
          <v-skeleton-loader v-else type="text" width="150"/>
          <v-divider class="my-1" />
          <span v-if="general">
            <span>CR: <strong>{{ general.cr }}</strong></span>
            <v-tooltip right max-width="450px">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" >
                  <v-btn rounded v-bind="attrs" icon v-on="on" x-small><v-icon>mdi-help-circle</v-icon></v-btn>
                </span>
              </template>
              <span>Конверсия</span>
            </v-tooltip>
            <br />
            <span>ACD: <strong>{{ general.acd }}</strong></span>
            <v-tooltip right max-width="450px">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" >
                  <v-btn rounded v-bind="attrs" icon v-on="on" x-small><v-icon>mdi-help-circle</v-icon></v-btn>
                </span>
              </template>
              <span>Средняя продолжительность вызова</span>
            </v-tooltip>
          </span>
          <v-skeleton-loader v-else type="text" width="150"/>
        </v-col>
        <v-col cols="12" md="6">
          <span v-if="progress">
            Прогресс кампании: <strong>{{ progress }}</strong>
          </span>
          <v-divider class="my-1" />
          <span v-if="general">
            Номеров в работе: <strong>{{ general.countNum }}</strong>
            <br />
            Сформирована статистика по номерам - <strong>{{ general.isProcessedStatistics }}</strong>
            <br />
            Успешные: <strong>{{ general.isGood }}</strong>
            <br />
            Не успешные: <strong>{{ general.isBad }}</strong>
          </span>
          <v-divider class="my-1" />
          <span v-if="sms">
            Всего СМС было отправлено за кампанию - <strong>{{ sms.all }}</strong>
            <br />
            Доставлено: <strong>{{ sms.delivered }}</strong>
            <br />
            Не доставлено: <strong>{{ sms.undelivered }}</strong>
            <br />
            Отклонено: <strong>{{ sms.rejected }}</strong>
            <br />
            Статус не известен: <strong>{{ sms.other }}</strong>
            <br />
            Ошибка: <strong>{{ sms.error }}</strong>
            <br />
          </span>
          <v-skeleton-loader v-else type="text" width="200"/>
        </v-col>
        <v-col cols="12">
          <v-divider class="my-1" />
          <span>
            <strong>Управление кампанией:</strong>
          </span>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn
            color="primary"
            outlined
            text
            small
            :loading="loadingUncalls"
            @click="create_uncalls_company">
            <v-icon left dark>mdi-phone-hangup</v-icon>
          Создать кампанию по неуспешным звонкам
          </v-btn>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn
            color="primary"
            outlined
            text
            small
            disabled
            @click="create_uncalls_company">
            <v-icon left dark>mdi-phone-settings</v-icon>
          Запустить повторно кампанию
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </header>
</template>

<script>
import { mapActions } from 'vuex';
import { DateTime } from 'luxon';
import timezones from '../../manager-autocall/timezones';

export default {
  name: 'Header',
  props: {
    autocall: {
      type: Object,
      required: true,
    },
    schedule: {
      type: Object,
      required: true,
    },
    bases: {
      type: Array,
      required: true,
    },
    progress: {
      type: String,
      required: true,
    },
    general: {
      type: Object && undefined,
      required: true,
    },
    sms: {
      type: Object && undefined,
      required: true,
    },
  },
  data() {
    return {
      loadingUncalls: false,
    };
  },
  computed: {
    timezone() {
      return timezones.find(timezone => timezone.value === (this.schedule?.tz || Math.round((new Date().getTimezoneOffset() / 60) * -1)));
    },
    dateStart() {
      return DateTime
        .fromISO(this.autocall.dateStart, { zone: 'UTC' })
        .plus({ hours: this.timezone.value || 0 })
        .toFormat('HH:mm, dd.MM.yyyy');
    },
    dateStop() {
      return DateTime
        .fromISO(this.autocall.dateStop, { zone: 'UTC' })
        .plus({ hours: this.timezone.value || 0 })
        .toFormat('HH:mm, dd.MM.yyyy');
    },
    dateEnd() {
      return DateTime
        .fromISO(this.autocall.dateEnd, { zone: 'UTC' })
        .plus({ hours: this.timezone.value || 0 })
        .toFormat('HH:mm, dd.MM.yyyy');
    },
    dateCreated() {
      return DateTime
        .fromISO(this.autocall.dateCreated, { zone: 'UTC' })
        .toFormat('dd.MM.yyyy');
    },
  },
  methods: {
    ...mapActions(['CREATE_AUTOCALL_FROM_UNCALLS', 'GET_INFORMATION_ON_AUTOCALL', 'GET_BASES', 'GET_AUTOCALL_PROGRESS', 'GET_INFORMATION_ON_BASE']),
    async create_uncalls_company() {
      this.loadingUncalls = true;
      const data = await this.CREATE_AUTOCALL_FROM_UNCALLS({ groupId: this.autocall.group, autocallId: this.autocall.id });
      await this.GET_INFORMATION_ON_AUTOCALL({ groupId: this.autocall.group, autocallId: data?.callingId });
      await this.GET_INFORMATION_ON_BASE({ id: data?.baseId });
      await this.GET_AUTOCALL_PROGRESS({ groupId: this.autocall.group, autocallId: data?.callingId });
      this.$notify({ text: 'Новая кампания создана!', type: 'success' });
      this.$router.push({ name: 'home' });
      this.loadingUncalls = false;
    },
  },
};
</script>
