<template>
  <div>
    <TreemapChart
      :series="series"
      :options="options"
    />
  </div>
</template>

<script>
import TreemapChart from '../../../../components/charts/TreemapChart.vue';

export default {
  name: 'SMSStatistics',
  components: { TreemapChart },
  props: {
    autocall: {
      type: Object,
      required: true,
    },
    sms: {
      type: Object && undefined,
      required: true,
    },
  },
  data() {
    return {
      options: {
        height: 200,
        title: {
          text: `CMC: ${this.autocall.name}`,
          align: 'center',
        },
      },
    };
  },
  computed: {
    series() {
      return [
        {
          data: [
            {
              x: 'Доставлено',
              y: this.sms.delivered,
            },
            {
              x: 'Не доставлено',
              y: this.sms.undelivered,
            },
            {
              x: 'Отклонено',
              y: this.sms.rejected,
            },
            {
              x: 'Ошибка',
              y: this.sms.error,
            },
            {
              x: 'Не известно',
              y: this.sms.other,
            },
          ],
        },
      ];
    },
  },
};
</script>
