<template>
  <div>
    <apexchart type="treemap" :height="chartOptions.height" :options="chartOptions" :series="series" />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import colors from 'vuetify/lib/util/colors';
import mixin from './mixin.vue';

export default {
  name: 'TreemapChart',
  components: {
    apexchart: VueApexCharts,
  },
  mixins: [mixin],
  computed: {
    defaultOptions() {
      return {
        chart: {
          type: 'treemap',
        },
        legend: {
          show: false,
        },
        colors: [
          '#26a33b',
          '#fe4f1a',
          '#0096d9',
          colors.cyan.darken1,
          colors.teal.darken1,
          colors.orange.darken1,
          colors.brown.darken1,
          colors.lime.darken1,
          colors.purple.darken1,
          colors.indigo.darken1,
        ],
        dataLabels: {
          enabled: true,
          formatter: (text, op) => (
            [text, op.value]
          ),
        },
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false,
          },
        },
      };
    },
  },
};
</script>
