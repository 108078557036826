<template>
  <div v-if="statistic" class="statistic">
    <v-row>
      <v-col class="mx-n2">
        <router-link to="/"><v-btn color="primary" dark class="mb-6 ml-2"><v-icon left dark>mdi-keyboard-backspace</v-icon>Назад</v-btn></router-link>
      </v-col>
    </v-row>
    <Header :autocall="autocall" :schedule="schedule" :bases="usedBases" :progress="progress" :general="general" :sms="sms" />
    <v-container class="mt-15">
      <!-- <v-row>
        <ChartContacts v-if="autocall.progress && general" :all-quantity-numbers="autocall.progress.countNumAll" :statistic="general" />

        <ChartAverageListeningTime v-if="averageTime" :statistic="averageTime"/>
      </v-row>

      <v-divider class="my-15" /> -->

      <!-- <ChartClickButtons v-if="buttonsPressed" :statistic="buttonsPressed" /> -->

      <!-- <v-divider class="my-15" /> -->
      <GeneralCampaignStatistics
        v-if="general.isProcessedStatistics"
        :general="general"
        :autocall="autocall"
      />
      <SMSStatistics
        v-if="sms.all"
        :sms="sms"
        :autocall="autocall"
      />
      <DetailStatistic
        v-if="statistic.numbers"
        :statistic="statistic"
      />
    </v-container>
    <DialogStatisticsNotReady :dialog="stLoads" @close="stLoads = false"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Header from './components/Header.vue';
import GeneralCampaignStatistics from './components/charts/GeneralCampaignStatistics.vue';
import SMSStatistics from './components/charts/SMSStatistics.vue';
// import ChartAverageListeningTime from './components/charts/ChartAverageListeningTime.vue';
// import ChartClickButtons from './components/charts/ChartClickButtons.vue';
import DetailStatistic from './components/DetailStatistic.vue';
import DialogStatisticsNotReady from './components/DialogStatisticsNotReady.vue';

export default {
  name: 'Statistic',
  components: {
    Header,
    GeneralCampaignStatistics,
    SMSStatistics,
    // ChartAverageListeningTime,
    // ChartClickButtons,
    DetailStatistic,
    DialogStatisticsNotReady,
  },
  data() {
    return {
      pageLoading: true,
      stLoads: false,
      schedule: {},
    };
  },
  computed: {
    ...mapGetters(['BASES', 'STATISTIC_TYPES', 'CONNECTED_BASE_IDS', 'GET_AUTOCALL_BY_ID']),
    autocall() {
      return this.GET_AUTOCALL_BY_ID(+this.$route.query.autocall) || {};
    },
    statistic() {
      return this.$store.getters.STATISTIC_BY_AUTOCALL_ID(this.$route.query.autocall);
    },
    general() {
      return this.statistic[this.STATISTIC_TYPES.GENERAL];
    },
    sms() {
      return this.statistic[this.STATISTIC_TYPES.SMS];
    },
    // averageTime() {
    //   return this.statistic[this.STATISTIC_TYPES.AVERAGE_TIME];
    // },
    // buttonsPressed() {
    //   return this.statistic[this.STATISTIC_TYPES.BUTTONS_PRESSED];
    // },
    usedBases() {
      return this.BASES?.length && this.CONNECTED_BASE_IDS[this.autocall.id]?.length
        ? this.BASES.filter(base => this.CONNECTED_BASE_IDS[this.autocall.id].includes(base.id))
        : [];
    },
    progress() {
      const percent = (this.statistic.progress.countNumCalling / this.statistic.progress.countNumAll) * 100;

      return Number.isNaN(percent) || percent === Infinity
        ? '0%'
        : percent > 100
          ? '100%'
          : `${percent}%`;
    },
    dialog: {
      get() {
        return this.stLoads;
      },
      set(stLoads) {
        this.stLoads = stLoads;
      },
    },
  },
  created() {
    this.fetchStatistic();
  },
  methods: {
    ...mapActions(['GET_STATISTIC', 'GET_NUMBERS_STATISTIC', 'GET_SCHEDULE']),
    async fetchStatistic() {
      const { group, autocall } = this.$route.query;

      this.schedule = await this.GET_SCHEDULE({ groupId: group, autocallId: autocall });

      // const { GENERAL, AVERAGE_TIME, BUTTONS_PRESSED } = this.STATISTIC_TYPES;
      const { GENERAL, SMS } = this.STATISTIC_TYPES;

      await Promise.all([
        this.GET_STATISTIC({ groupId: group, autocallId: autocall, type: GENERAL }),
        this.GET_STATISTIC({ groupId: group, autocallId: autocall, type: SMS }),
        // this.GET_STATISTIC({ groupId: group, autocallId: autocall, type: BUTTONS_PRESSED }),
        this.GET_NUMBERS_STATISTIC({ groupId: group, autocallId: autocall }),
      ]);

      this.stLoads = (this.statistic[GENERAL].countNum !== this.statistic[GENERAL].isProcessedStatistics);

      this.pageLoading = false;
    },
  },
};
</script>

<style>
.statistic .apexcharts-legend {
  min-height: 42px !important;
  align-items: flex-start;
}
</style>
